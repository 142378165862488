<template>
  <slot />
</template>
<script lang="ts" setup>
import './main.css'

import { toHead } from 'vue-datocms'
import { models as __models } from '~/integrations/dato/dato.js'

const { locale } = useI18n()
const metasData = await __models.metas.getMetas()

const layout = await __models.layout.getLayout('main')

useHead(() => {
  return toHead(metasData?.faviconMetaTags || [])
})
</script>
